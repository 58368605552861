import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './InstitutionRatings.module.scss';

interface RatingItem {
  name: string;
  value: number;
}

const InstitutionRatingsData: RatingItem[] = [
  {
    name: 'Cost and Fees (per year) ',
    value: 0,
  },
  {
    name: 'Education Level',
    value: 0,
  },
  {
    name: 'Internship and Career Opportunities',
    value: 0,
  },
  {
    name: 'Campus Life',
    value: 0,
  },
  {
    name: 'Campus Facilities',
    value: 0,
  },
];

const Grade: string[] = ['Awful', 'Bad', 'Ok', 'Good', 'Excellent'];

const InstitutionRatings = () => {
  const { t } = useTranslation('publicJobSidebar');
  const [ratings, setRatings] = useState<RatingItem[]>(InstitutionRatingsData);

  useEffect(() => {
    const storedRatings = localStorage.getItem('institutionRatings');
    if (storedRatings) {
      setRatings(JSON.parse(storedRatings));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('institutionRatings', JSON.stringify(ratings));
  }, [ratings]);

  const handleRatingClick = (itemIndex: number, value: number) => {
    const updatedRatings = ratings.map((item, index) => {
      if (index === itemIndex) {
        if (value === 1 && item.value === 1) {
          return { ...item, value: 0 };
        }
        return { ...item, value };
      }
      return item;
    });
    setRatings(updatedRatings);
  };

  return (
    <div className={styles.ratings}>
      <div className={styles.infoHeader}>
        <span className={styles.ratingsHeaderTitle}>
          <span>{t('InstitutionRatings.InstitutionRatings')}</span>
        </span>
      </div>
      <div className={styles.grades}>
        {Grade.map((grade, index) => (
          <span key={index} className={styles.grade}>
            {grade}
          </span>
        ))}
      </div>
      <div className={styles.ratingItems}>
        {ratings.map((item, itemIndex) => (
          <div key={itemIndex} className={styles.ratingItem}>
            <span>{t(`ratings.${item.name}`)}</span>
            <div className={styles.ratingDivIcon}>
              {[...Array(5)].map((_, i) => (
                <span
                  key={i}
                  className={styles.ratingIcon}
                  style={{
                    background:
                      i < item.value
                        ? `linear-gradient(
                        270deg,
                        #01ada9 0%,
                        rgba(1, 173, 169, 0.81) 42.52%,
                        rgba(1, 173, 169, 0.61) 105.41%,
                        rgba(1, 173, 169, 0) 117.65%
                      )`
                        : 'none',
                    borderRight: '2px solid #fff',
                  }}
                  onClick={() => handleRatingClick(itemIndex, i + 1)}></span>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InstitutionRatings;
